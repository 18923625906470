
import { GET_CATEGORIES } from '@/api/apolloHomePageQueries';

export default {
  name: 'HomeViewCategory',
  data() {
    return {
      categories: null,
      isLoading: false,
      fetchingIsFinished: false,
    };
  },
  props: {
    allDepartmentLink: {
      type: Object,
      default() {
        return {};
      },
    },
    shopAllBrandLink: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const { home } = (await this.$graphql.default.request(GET_CATEGORIES)) || {};
        if (home.category) {
          this.categories = home.category;
        } else {
          console.error('Invalid response format:', home);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async fetch() {
    await this.fetchData();
    this.fetchingIsFinished = true;
  },
};
