
import placeholder from '@/assets/placeholder-animation.svg';

export default {
  name: 'HomeViewCategoryItems',
  data() {
    return {
      brandIconSrc:
        '/skin/frontend/onestopbedrooms/default/images/1sb-svg-icons-redesign/single-color/icon-sb-brand-icon-2.png',
      brandTitle: 'Shop by Brand',
      placeholder,
      nbsp: '\u00A0', // no-break space char
      fakeList: new Array(9),
    };
  },
  props: {
    allDepartmentLink: {
      type: Object,
      default() {
        return {};
      },
    },
    shopAllBrandLink: {
      type: Object,
      default() {
        return {};
      },
    },
    categories: {
      type: Object,
      default: () => {},
    },
    fetchingIsFinished: {
      type: Boolean,
      default: false,
    },
    titleSmallText: {
      type: String,
    },
    titleLargeText: {
      type: String,
    },
  },
  computed: {
    formattedCategories() {
      return this.categories?.banner?.map((category) => {
        const formatSrcset = (sources) => sources
          ?.map(
            (source) => `${source.srcset} ${source.media.includes(':x1') ? '1x' : '2x'}`,
          )
          .join(', ');

        const combinedSrcset768px = formatSrcset(
          category?.image?.sources?.filter(
            (source) => source.media === '(min-width: 768px):x1'
              || source.media === '(min-width: 768px):x2',
          ),
        );

        const combinedSrcsetX = formatSrcset(
          category?.image?.sources?.filter(
            (source) => source.media === 'x1' || source.media === 'x2',
          ),
        );

        const srcset = [
          {
            media: '(min-width: 768px)',
            srcset: combinedSrcset768px,
            key: 'source0',
          },
          {
            srcset: combinedSrcsetX,
            key: 'source1',
          },
        ];

        return {
          ...category,
          srcset,
          imageSrc: category?.image?.sources?.[0]?.srcset,
        };
      });
    },
  },
};
