import gql from 'graphql-tag';
import {
  FRAGMENT_FEATURED_BRAND,
  ADDITIONAL_LINKS,
  CLOUD_LOOKS,
  HOME_SET_COLLECTION,
  POPULAR_CATEGORIES,
  CATEGORIES,
  FRAGMENT_BANNER_DYNAMIC,
  FRAGMENT_LEFT_BANNER,
  FRAGMENT_RIGHT_BANNER,
  FRAGMENT_BOTTOM_BANNER,
  FRAGMENT_CENTER_BANNER,
} from './apolloHomeFragment';

export const GET_BANNER_TYPE = gql`
query getBannerType {
    home {
      useStaticBanner
    }
  },
`;

export const GET_FEATURED_BRAND_DATA = gql`
${FRAGMENT_FEATURED_BRAND}
  query getFeaturedBrandData {
    home {
      featureBrand {
        ...fragmentFeaturedBrand
      }
    }
  },
`;

export const GET_ADDITIONAL_LINKS = gql`
${ADDITIONAL_LINKS}
  query getAdditionalLinks {
    home {
      additionalLinks {
        ...fragmentAdditionalLinks
      }
    }
  },
`;

export const GET_CLOUD_LOOKS = gql`
${CLOUD_LOOKS}
  query getCloudLooks {
    home {
      cloudLooks {
        ...fragmentCloudLooks
      }
    }
  },
`;

export const GET_HOME_SET_COLLECTION = gql`
${HOME_SET_COLLECTION}
  query getHomeSetCollection {
    home {
      homeSetCollection {
        ...fragmentHomeSetCollection
      }
    }
  },
`;

export const GET_POPULAR_CATEGORIES = gql`
${POPULAR_CATEGORIES}
  query getPopularCategories {
    home {
      popularCategories {
        ...fragmentPopularCategories
      }
    }
  },
`;

export const GET_CATEGORIES = gql`
${CATEGORIES}
  query getCategories {
    home {
      category {
        ...fragmentCategories
      }
    }
  },
`;

export const GET_BANNERS_DYNAMIC = gql`
${FRAGMENT_BANNER_DYNAMIC}
  query getBannersDynamic {
    home {
      banner {
        center {
          ...fragmentBannerDynamic
        }
        left {
          ...fragmentBannerDynamic
        }
        right {
          ...fragmentBannerDynamic
        }
        bottom {
          ...fragmentBannerDynamic
        }
      }
    }
  },
`;

export const GET_BANNERS_STATIC = gql`
${FRAGMENT_LEFT_BANNER}
${FRAGMENT_RIGHT_BANNER}
${FRAGMENT_BOTTOM_BANNER}
${FRAGMENT_CENTER_BANNER}
  query getBannersStatic {
    home {
      staticBanner {
        left {
          ...fragmentLeftBanner
        }
        center {
          ...fragmentCenterBanner
        }
        right {
          ...fragmentRightBanner
        }
        bottom {
          ...fragmentBottomBanner
        }
      }
    }
  },
`;
