import gql from 'graphql-tag';

export const FRAGMENT_IMAGE = gql`
fragment fragmentHomeImage on HomeImageType {
  alt
  src
  sources {
    media
    srcset
  }
}
`;

export const FRAGMENT_FEATURED_BRAND = gql`
${FRAGMENT_IMAGE}
  fragment fragmentFeaturedBrand on FeatureBrandType {
    banners {
      identifier
      slug
      image {
        ...fragmentHomeImage
      }
      logo {
        src
        alt
      }
      internalRoute
    }
  }
`;

export const ADDITIONAL_LINKS = gql`
  fragment fragmentAdditionalLinks on HomeBannerType {
    identifier
    lgText
    smText
    slug
    internalRoute
  }
`;

export const CLOUD_LOOKS = gql`
fragment fragmentCloudLooks on CloudLooksType {
  title
  button {
    title
    slug
    internalRoute
  }
  banners {
    identifier
    slug
    image {
      src
      alt
    }
    internalRoute
  }
}
`;

export const HOME_SET_COLLECTION = gql`
${FRAGMENT_IMAGE}
fragment fragmentHomeSetCollection on HomeSetColType {
  smText
  lgText
  image {
    src
    alt
  }
  banners {
    identifier
    lgText
    slug
    image {
      ...fragmentHomeImage
    }
    internalRoute
  }
}
`;

export const POPULAR_CATEGORIES = gql`
fragment fragmentPopularCategories on PopularCatType {
  title
  button {
    title
    slug
    internalRoute
  }
  image {
    src
    alt
  }
  children {
    identifier
    smText
    slug
    image {
      src
      alt
    }
    internalRoute
  }
}
`;

export const CATEGORIES = gql`
${FRAGMENT_IMAGE}
fragment fragmentCategories on CategoryQueries {
  smText
  lgText
  banner {
    identifier
    smText
    slug
    image {
      ...fragmentHomeImage
    }
    internalRoute
  }
}
`;

export const FRAGMENT_BANNER_DYNAMIC = gql`
fragment fragmentBannerDynamic on BannerSection {
  slug
  content {
    html
    style
  }
  background {
    banner
    video
    alt
  }
  useInSlider
  speed
  internalRoute
  isMobile
  isTablet
  isDesktop
  displayPage
}
`;

export const FRAGMENT_LEFT_BANNER = gql`
fragment fragmentLeftBanner on LeftBannerSection {
  display
  banners {
    identifier
    smText
    lgText
    slug
    image {
      alt
      src
    }
    internalRoute
  }
}
`;

export const FRAGMENT_RIGHT_BANNER = gql`
fragment fragmentRightBanner on RightBannerSection {
  display
  banners {
    identifier
    smText
    lgText
    slug
    image {
      alt
      src
    }
    internalRoute
  }
}
`;

export const FRAGMENT_BOTTOM_BANNER = gql`
fragment fragmentBottomBanner on BottomBannerSection {
  display
  banners {
    identifier
    smText
    lgText
    slug
    image {
      alt
      src
    }
    internalRoute
  }
}
`;

export const FRAGMENT_CENTER_BANNER = gql`
fragment fragmentCenterBanner on CenterBannerSection {
  display
  slider {
    id
    title
    showTitle
    speed
    controlNav
    animation
    cssClass
    banners {
      id
      name
      order
      bannersliderId
      status
      clickUrl
      image
      imageAlt
      displayType
      identifier
      bannerVideo
    }
  }
  banners {
    identifier
    smText
    lgText
    slug
    logo {
      alt
      src
    }
    image {
      alt
      src
    }
    internalRoute
  }
}
`;
